
























import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Error extends Vue {
  // errorMessage?: string = this.$store.state.errorInfo!.message || '抱歉，页面出错了。'
  get errorCode() {
    return this.$route.query.code
  }

  get errorMessage() {
    let message
    const errorCode = this.errorCode
    if (errorCode === '1000') {
      message = '服务器繁忙'
    } else if (errorCode === '1001') {
      message = '找不到店铺'
    } else if (this.$store.state.errorInfo!.message) {
      message = this.$store.state.errorInfo!.message
    } else {
      message = '抱歉，页面出错了'
    }
    return message
  }
  get getIsShowGoHome() {
    if (this.errorMessage === '抱歉，该页面已失效，暂时无法访问') {
      return false
    } else {
      return true
    }
  }

  toRefresh() {
    window.location.reload()
  }
  toHome() {
    this.$router.replace({ name: 'home' })
  }
}
